import axios from "axios";
import Cookies from "js-cookie";
import { TableResponse } from "@/models/TableResponse";
import { TableConfig } from "@/models/TableConfig";
import { AmuraManeuver } from "@/models/AmuraManeuver";

const querystring = require("querystring");

const ENDPOINT_PATH = process.env.VUE_APP_MDES_API;
const LOGIN_PATH = process.env.VUE_APP_MDES_LOGIN_PATH;

export default {
  setUserMDESLogged(userMDESLogged) {
    Cookies.set("userMDESLogged", JSON.stringify(userMDESLogged.data), {
      expires: 365,
    });
  },
  getUserMDESLogged() {
    return Cookies.get("userMDESLogged") != null
      ? JSON.parse(Cookies.get("userMDESLogged"))
      : null;
  },
  loginMDES(username, password) {
    const user = { username, password };
    Cookies.set("userMDESCredentials", JSON.stringify(user), { expires: 365 });

    return axios.post(
      ENDPOINT_PATH + LOGIN_PATH,
      querystring.stringify({
        UserLogin: user.username,
        Password: user.password,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  logoutMDES() {
    Cookies.remove("userMDESLogged");
    Cookies.remove("userMDESCredentials");
  },
  refreshMDES() {
    Cookies.remove("userMDESLogged");
    const user = JSON.parse(Cookies.get("userMDESCredentials"));
    return axios.post(
      ENDPOINT_PATH + LOGIN_PATH,
      querystring.stringify({
        UserLogin: user.username,
        Password: user.password,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  getForecastList(offset: number, limit: number) {
    return axios.get(
      ENDPOINT_PATH +
      "/maneuvers?status=Authorized&offset=" +
      offset +
      "&limit=" +
      limit,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(Cookies.get("userMDESLogged")).Ticket,
        },
      }
    );
  },
  getManeuveringList(offset: number, limit: number) {
    return axios.get(
      ENDPOINT_PATH +
      "/maneuvers?status=Initiated&offset=" +
      offset +
      "&limit=" +
      limit,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(Cookies.get("userMDESLogged")).Ticket,
        },
      }
    );
  },
  getNotAllowedList(offset: number, limit: number) {
    return axios.get(
      ENDPOINT_PATH +
      "/maneuvers?status=Pending&offset=" +
      offset +
      "&limit=" +
      limit,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(Cookies.get("userMDESLogged")).Ticket,
        },
      }
    );
  },
};
